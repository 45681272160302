import './styles/NavbarINeed.scss';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
  ContactCountryModel,
  MenuCategory,
  MenuItem,
  NewMenuItem
} from '../../../../types/dto/MenuModel';
import chatImage from '../../../../assets/images/ineed_chat.svg';
import emailImage from '../../../../assets/images/ineed_email.svg';
import phoneImage from '../../../../assets/images/ineed_phone.svg';
import { useTranslation } from 'react-i18next';
import WithRtl from '../../../../shared/WithRtl';
import { FC, lazy, useCallback, useEffect, useRef, useState } from 'react';
import { appHelper } from '../../../../helpers/appHelper';
import { useIsMobileShared } from '../../../../hooks/useIsMobileShared';
import Carousel from '../../../../shared/Carousel/Carousel';
const RTL = lazy(() => import('./NavbarINeedRtl'));

type Props = {
  iNeedData?: NewMenuItem;
  contactTab?: ContactCountryModel;
};

const NavbarINeed = ({ iNeedData = null, contactTab = null }: Props) => {
  const { t } = useTranslation();

  const translations = {
    zimBelives: t('navbar.INeed.ZimBelieves'),
    emailUs: t('navbar.INeed.Email'),
    letsChat: t('navbar.INeed.Chat')
  };
  const iNeedRef = useRef(null);
  const LPMShadowDiv = document.querySelector<HTMLElement>('[id*="LPMcontainer-"] .shadow-div');
  const microsoftChatIframe = document.getElementById(
    'Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window'
  );

  useEffect(() => {
    //Init Logic for tabs arrows
    if (iNeedRef?.current) {
      const tabs: HTMLCollection = iNeedRef.current?.querySelectorAll('.nav-item');
      if (tabs && tabs?.length) {
        [...tabs]?.map((tab, index) => {
          if (index !== tabs?.length - 1) {
            const node = document.createElement('span');
            node.classList.add('arrow-button');
            if (index === 0) node.classList.add('active');
            tab.appendChild(node);
          }
        });
      }
    }
  }, []);

  const slidesToShow = 9;
  const settings = {
    infinite: slidesToShow <= 8 ? false : false,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          vertical: true,
          infinite: false,
          arrows: false
        }
      }
    ]
  };

  const onChatClick = () => {
    if (window['executeChatbot']?.()) {
      window['executeChatbot']();
    } else if (LPMShadowDiv) {
      LPMShadowDiv.click();
    }
  };

  //Logic for tabs arrows
  const onTabSelect = (eventKey: string) => {
    if (iNeedRef?.current) {
      const tabs: HTMLCollection = iNeedRef.current?.querySelectorAll('.nav-item');
      if (tabs && tabs?.length) {
        [...tabs]?.map((tab, index) => {
          if (index !== tabs?.length - 1) {
            const arrowSpan = tab?.querySelector('.arrow-button');
            if (!appHelper.isObjectEmpty(arrowSpan)) {
              if (index === parseInt(eventKey) && !arrowSpan.classList.contains('active'))
                arrowSpan.classList.add('active');
              else arrowSpan.classList.remove('active');
            }
          }
        });
      }
    }
  };
  type iNeedItemProps = {
    subImage: MenuItem;
  };
  const INeedItem: FC<iNeedItemProps> = ({ subImage }) => {
    return (
      <a className="icon-link" href={subImage?.url} target={subImage?.target}>
        <img src={subImage?.image}></img>
        <div className="image-title">{subImage?.title}</div>
      </a>
    );
  };

  const isCarouselVertical = useIsMobileShared(991);

  const getINeedChild = (tab: MenuCategory) => {
    return tab.items.map((subImage, index) => (
      <INeedItem subImage={subImage} key={`sub_image_${index}`} />
    ));
  };

  return (
    <>
      <WithRtl lazyElement={RTL} />
      {iNeedData && (
        <div ref={iNeedRef} className="i-need">
          {iNeedData?.categories?.length && (
            <Tabs defaultActiveKey={0} id="ineed-category" className="mb-3" onSelect={onTabSelect}>
              {iNeedData?.categories?.map((tab, index) => (
                <Tab key={`tab_${index}`} eventKey={index} title={`${tab?.title}`}>
                  {tab?.items?.length && (
                    <>
                      {!isCarouselVertical && <Carousel show={9}>{getINeedChild(tab)}</Carousel>}
                      {isCarouselVertical && (
                        <div className="carousel">
                          <div className="carousel-inner vertical-mode">
                            {tab.items.map((subImage, index) => (
                              <div className="carousel-item" key={`sub_image_${index}`}>
                                <INeedItem subImage={subImage} />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </Tab>
              ))}
            </Tabs>
          )}
          <div className="contact-row">
            <div className="zim-believes-text">{translations.zimBelives}</div>
            <div className="icons-row">
              {!microsoftChatIframe ? (
                <div className="contact-icon" onClick={onChatClick}>
                  <img className="iconImage" src={chatImage} />
                  <a>{translations.letsChat}</a>
                </div>
              ) : (
                <div className="contact-icon-omnichannel"></div>
              )}

              {!!contactTab?.generalContacts?.length && contactTab.generalContacts[0]?.email && (
                <div className="contact-icon">
                  {' '}
                  <img className="iconImage" src={emailImage} />
                  <a href={`mailto:${contactTab.generalContacts[0]?.email}`}>
                    {translations.emailUs}
                  </a>
                </div>
              )}
              {!!contactTab?.generalContacts?.length && contactTab.generalContacts[0]?.phone && (
                <div className="contact-icon">
                  {' '}
                  <img className="iconImage phone" src={phoneImage} />
                  <a href={`tel:${contactTab.generalContacts[0]?.phone}`}>
                    {contactTab.generalContacts[0]?.phone}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NavbarINeed;
