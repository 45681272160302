import { useLayoutEffect, useState } from 'react';
import { debounce } from '../helpers/debounce';

export const useIsMobileNewUi = () => {
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const resize = () => {
      const width =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      setIsMobile(() => {
        return window.screen.width <= 1280 || width <= 1280;
      });
    };
    resize();
    window.addEventListener('resize', debounce(resize));

    return window.removeEventListener('resize', resize);
  }, []);

  return isMobile;
};
